import React from "react"
import Popup from "reactjs-popup"
import * as styles from "./AdoptForm.module.scss"

// import handler from "../../api/sendgrid"

const overlayStyle = { background: "rgba(0,0,0,0.7)" }


export const AdoptForm = (props) => {

    const [serverResponse, setServerResponse] = React.useState(``)

    // console.log(props.object)

    async function onSubmit(e) {
        e.preventDefault()

        const data = new FormData(e.currentTarget)

        let values = {};
        for (let [key, value] of data) {
            values[key] = value;
        }

        // console.log(values)
        
        const response = await window
          .fetch(`/api/brevo`, {
            method: `POST`,
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify(values),
          })
          .then(res => res.json())
        //   console.log(response)
        if(response.message === 'Thank you for adopting this object!') {
            window.location.href = props.object.adoption.adoption.giftTapLink.url;
        }


        // setServerResponse(response)
    }


    return (
        <Popup trigger={<button className={styles.action}> Adopt </button>} modal nested {...{ overlayStyle }}>
            {(close) => (
                <div className={styles.modal}>
                    <button className={styles.close} onClick={close}>
                        &times;
                    </button>

                    <form id="AdoptForm" action="/api/brevo" onSubmit={(e) => { onSubmit(e)}} method="POST" className={styles.form}>

                        <input type="hidden" name="object" value={props.object.title} />
                        <div className={styles.form_group}>
                            <label htmlFor=" first name">First Name</label>
                            <input className="form-control" name="given-name" id="given-name" />
                        </div>

                        <div className={styles.form_group}>
                            <label htmlFor=" last name">Last Name</label>
                            <input className="form-control" name="last-name" id="last-name" />
                        </div>

                        <div className={styles.form_group}>
                            <label htmlFor="email">Email address (required)</label>
                            <input type="email" className="form-control" name="email" id="email" placeholder="name@example.com" required/>
                        </div>

                        <div className={styles.form_group__large}>
                            <label htmlFor="dedication">What name/text would you like to be listed next to the object on the website?</label>
                            <span>This can be you or a loved one</span>
                            
                            <div>
                                <input type="text" className="form-control" name="dedication" id="dedication" />
                            </div>
                        </div>

                        <div className={styles.form_group__large}>
                            <label htmlFor="reason">Please tell us the reason for wanting to adopt</label>
                            <textarea name="adoption-reason" form="AdoptForm"></textarea>
                        </div>
                        
                        <div className={`${styles.form_group__large} ${styles.checkbox}`}>
                            <input type="checkbox" className="form-control" name="certificate" id="certificate" />
                            <label htmlFor="certificate">Adoption certificate</label>
                            <span>I would like to receive a physical certificate alongside my digital copy.</span>
                        </div>

                        <div className={styles.form_group__large}>
                            <label htmlFor="reason">Please provide your postal address to receive a physical certificate</label>
                            <textarea name="postal-address" form="AdoptForm"></textarea>
                        </div>

                        <div className={styles.preferences_disclaimer}>Derby Museums would love to keep in touch with you, but we also want to make sure we have your consent to do so. Please select your communication preferences below to receive specific content that is relevant and interesting to you. You will be able to withdraw your consent at any time and Derby Museums will never share your data with third parties.</div>

                        
                        <div className={`${styles.form_group__large} ${styles.checkbox}`}>
                            <input type="checkbox" className="form-control" name="mailing-list" id="mailing-list" />
                            <label htmlFor="mailing-list">What’s On at Derby Museums</label>
                            <span>I would like to receive information about events, exhibitions, fundraising campaigns and family activities.</span>
                        </div>

                        <div className={`${styles.form_group__large} ${styles.checkbox}`}>
                            <input type="checkbox" className="form-control" name="opportunities" id="opportunities" />
                            <label htmlFor="opportunities">Volunteer Opportunities Newsletter</label>
                            <span>I would like to receive information about volunteering activities and opportunities.</span>
                        </div>

                        <div className={`${styles.form_group__large} ${styles.checkbox}`}>
                            <input type="checkbox" className="form-control" name="corporate" id="corporate" />
                            <label htmlFor="corporate">Corporate Newsletter</label>
                            <span>I would like to receive information about corporate events, venue hire, corporate volunteering, etc.</span>
                        </div>

                        <div className={`${styles.form_group__large} ${styles.consent}`}>
                            <strong>Gift Aid your donation to raise 25% more!</strong>
                            <span>Using Gift Aid means that for every pound you give, we can get an extra 25 pence from HM Revenue & Customs; helping your gift go further.</span>
                        
                            <div className={styles.checkbox}>
                                <input type="checkbox" className="form-control" name="giftaid" id="giftaid" />
                                <label htmlFor="giftaid" className="required">I would like Derby Museums to treat all donations and subscriptions I have made in the last 4 years and those I make in future as Gift Aid donations until further notice. I am a UK taxpayer and understand that if I pay less Income Tax and/or Capital Gains Tax than the amount of Gift Aid claimed on all my donations in that tax year it is my responsibility to pay any difference.</label>
                            </div>
                        </div>

                        <div className={`${styles.form_group__large} ${styles.consent}`}>
                            <strong>Museum of Making Slideshow Consent</strong>
                            <span>We'd love to display your name and who you've adopted the object for in a slideshow projected at the Museum of Making. Please tick the box if you consent to this</span>
                        
                            <div className={styles.checkbox}>
                                <input type="checkbox" className="form-control" name="consent" id="consent" />
                                <label htmlFor="consent" className="required">I consent to this</label>
                            </div>
                        </div>

                        <div>{serverResponse}</div>

                        <div className={styles.form_group__large}>
                            <button className={styles.action} type="submit">
                                Proceed to payment
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </Popup>
    )
}
export default AdoptForm
