import React from "react"
import withPreview, { getContent } from "../apollo/preview"
import { getEventPreview as PREVIEW_QUERY } from "../preview-queries/eventPreview"

// Data
import { graphql } from "gatsby"

// Components
import Layout from "../components/Global/Layout"
import Main from "../components/Shared/Main"
import Sidebar from "../components/Shared/Sidebar"
import Article from "../components/Shared/Article"
import CTA from "../components/Shared/CTA"
import ObjectHero from "../components/Object/ObjectHero"
import ObjectMeta from "../components/Object/ObjectMeta"
import AdoptForm from "../components/Object/AdoptForm"
import Content from "../components/Shared/Content"
import SectionTitle from "../components/Shared/SectionTitle"
import CentredContentWithSidebar from "../components/Shared/CentredContentWithSidebar"
import { venueForPage } from "../helpers/venueForPage"
import SEOSettings from "../components/Shared/SEOSettings"

const ObjectTemplate = (props) => {
    const object = getContent(props.data, "object")

    const venue = venueForPage({
        page: object,
        menus: props.data.menus,
    })

    // console.log("Event ", object)
    // console.log("Event props ", props)
    // console.log("Event venue ", venue)

    // const eventTheme = object.event_fields.displayTheme
    let themeClass = "EventTheme3"

    // const mulberry32 = (a) => {
    //     return function () {
    //         var t = (a += 0x6d2b79f5)
    //         t = Math.imul(t ^ (t >>> 15), t | 1)
    //         t ^= t + Math.imul(t ^ (t >>> 7), t | 61)
    //         return ((t ^ (t >>> 14)) >>> 0) / 4294967296
    //     }
    // }

    // switch (eventTheme) {
    //     case "theme_one":
    //         themeClass += "1"
    //         break
    //     case "theme_two":
    //         themeClass += "2"
    //         break
    //     case "theme_three":
    //         themeClass += "3"
    //         break
    //     default:
    //         // Create a random theme class based on title
    //         var ar = object.title.substring(0, 3).split("")
    //         for (let i = 0; i < ar.length; i++) {
    //             ar[i] = ar[i].charCodeAt(0)
    //         }
    //         if (typeof Math.imul === "undefined") {
    //             themeClass = "EventTheme1"
    //         } else {
    //             let rng = mulberry32(Number(ar.join("")))
    //             themeClass += Math.floor(rng() * 3) + 1
    //         }
    // }

    return (
        <Layout venue={venue} currentLocation={props.path} title={object.title}>
            <SEOSettings entity={object} />
            <ObjectHero
                image={object.image.image}
                title={object.title}
                subtitle={``}
                venue={object.venueFields?.venue?.title}
                backlink="/adopt-an-object"
                theme={themeClass}
            />

            <Main theme={themeClass}>
                <CentredContentWithSidebar>
                    <Content>
                        {/*<EventTitle>Process and progress The creative journeys of Ronald Pope and the artists of the Jonathan Vickers Fine Art Award</EventTitle>*/}
                        <Article>
                            <strong>Object details:</strong>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: object.content,
                                }}
                            ></div>
                        </Article>
                    </Content>

                    <Sidebar>
                        <ObjectMeta object={object} adoption={object.adoption} adoptUrl={null} />
                    </Sidebar>
                </CentredContentWithSidebar>

                {/* <AdoptForm></AdoptForm> */}
                {/* //onSubmit={object.adoption.adoption.giftTapLink.url} */}
            </Main>
        </Layout>
    )
}

export const pageQuery = graphql`
    query ObjectQuery($databaseId: Int!) {
        menus: allWpMenu {
            results: nodes {
                ...PageMenusNode
            }
        }

        object: wpObject(databaseId: { eq: $databaseId }) {
            id
            databaseId
            title
            content
            link
            excerpt
            image: hero_fields {
                image {
                    sourceUrl
                    altText
                    title
                }
            }
            adoption {
                adoption {
                    adoptedBy
                    giftTapLink {
                        target
                        title
                        url
                    }
                    suggestedDonation {
                        name
                    }
                }
            }
             venueFields {
                 venue {
                     ...VenueContent
                 }
             }
            seo {
                metaDesc
                title
                opengraphTitle
                opengraphDescription
                metaKeywords
                metaRobotsNofollow
                metaRobotsNoindex
                opengraphImage {
                  sourceUrl
                }
            }
        
        }
    }
`

export default withPreview({ preview: PREVIEW_QUERY })(ObjectTemplate)
