import React from "react"
import Popup from "reactjs-popup"

import * as styles from "./ObjectMeta.module.scss"
import AdoptForm from "./AdoptForm"

const ObjectMeta = (props) => {
    const adopted = props.adoption?.adoption.adoptedBy ? true : false
    // console.log("adopted: ", adopted)
    const message = adopted ? adopted : "Be the first to adopt this object! Click on the Adopt button below"

    return (
        <div className={styles.ObjectMeta}>
            <div className={styles.information}>
                <span className={styles.date}>Adopted by: </span>
                {!adopted ? (
                    <>
                        <span className={styles.info}>Be the first to adopt this object! Click on the Adopt button below</span>
                        <strong>Suggested donation to adopt:</strong>
                        <span>{`£${props.adoption.adoption.suggestedDonation.name}`}</span>
                    </>
                ) : (
                    <>
                        <p> {props.adoption?.adoption.adoptedBy} </p>
                        <span className={styles.info}> Want to join them? Click on the Adopt button below</span>
                        <strong>Suggested donation to adopt:</strong>
                        <span>{`£${props.adoption.adoption.suggestedDonation.name}`}</span>
                    </>
                )}
            </div>

            
                <div className={styles.actions}>
                    {/* <a href={props.adoption.adoption.giftTapLink.url} aria-label="adopt this object">
                        Adopt
                     </a> */}
                    <AdoptForm object={props.object}></AdoptForm>
                </div>
            
        </div>
    )
}

export default ObjectMeta
