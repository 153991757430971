// extracted by mini-css-extract-plugin
export var action = "AdoptForm-module--action--726b1";
export var checkbox = "AdoptForm-module--checkbox--757ef";
export var checkboxes = "AdoptForm-module--checkboxes--5ab13";
export var close = "AdoptForm-module--close--9d88d";
export var consent = "AdoptForm-module--consent--c7ae8";
export var form = "AdoptForm-module--form--119be";
export var formActions = "AdoptForm-module--formActions--8253d";
export var formGroup = "AdoptForm-module--formGroup--a35f4";
export var formGroupErrors = "AdoptForm-module--formGroupErrors--10548";
export var formGroupSingleCheckbox = "AdoptForm-module--formGroupSingleCheckbox--59621";
export var formGroupWithErrors = "AdoptForm-module--formGroupWithErrors--87a97";
export var formGroupWordyLabel = "AdoptForm-module--formGroupWordyLabel--194e9";
export var form_button = "AdoptForm-module--form_button--596b0";
export var form_group = "AdoptForm-module--form_group--66571";
export var form_group__large = "AdoptForm-module--form_group__large--bd50c";
export var guideText = "AdoptForm-module--guideText--20d0e";
export var helperText = "AdoptForm-module--helperText--8ac9d";
export var labelSubstitute = "AdoptForm-module--labelSubstitute--d8073";
export var modal = "AdoptForm-module--modal--5bf7c";
export var preferences_disclaimer = "AdoptForm-module--preferences_disclaimer--2807d";
export var radioButton = "AdoptForm-module--radioButton--84e05";
export var radioButtons = "AdoptForm-module--radioButtons--78ff2";
export var required = "AdoptForm-module--required--49804";
export var section = "AdoptForm-module--section--9a7b2";